import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Hizmetlerimiz | RoadCrafters Sürüş Akademisine
			</title>
			<meta name={"description"} content={"Direksiyon Başında Ustalık ve Güven Yolculuğuna Çıkın"} />
			<meta property={"og:title"} content={"Hizmetlerimiz | RoadCrafters Sürüş Akademisine"} />
			<meta property={"og:description"} content={"Direksiyon Başında Ustalık ve Güven Yolculuğuna Çıkın"} />
			<meta property={"og:image"} content={"https://wovado.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://wovado.com/img/3381772.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://wovado.com/img/3381772.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://wovado.com/img/3381772.png"} />
			<meta name={"msapplication-TileImage"} content={"https://wovado.com/img/3381772.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h1"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Hizmetlerimiz
						</Text>
						<Text as="p" margin="12px 0" font="--lead" md-font="--headline3">
							RoadCrafters Sürüş Akademisi'nde, her öğrencinin ihtiyaçlarını ve isteklerini karşılamak için özel olarak tasarlanmış çok çeşitli sürüş kursları sunmaya kararlıyız. Acemilerden becerilerini geliştirmek isteyen deneyimli sürücülere kadar, kurslarımız sizin başarınız düşünülerek tasarlanmıştır. İşte neler bekleyebileceğinize daha yakından bir bakış:
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://wovado.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://wovado.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://wovado.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Başlangıç Sürüş Kursu
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Amaç: Yeni sürücüleri güvenli ve kendinden emin sürüş için temel beceri ve bilgilerle donatmak.
						<br /><br />
						Özellikler: Trafik yasalarının, araç kullanımının temellerinin ve güvenli sürüş uygulamalarının kapsamlı bir şekilde anlaşılması. Çeşitli yol türleri ve koşullarında kapsamlı uygulamalı sürüş pratiği.
						<br /><br />
						Süre: 30 saat sınıf eğitimi ve 10 saat direksiyon başında eğitim.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Defansif Sürüş Kursu
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Amaç: Sürücülerin yoldaki tehlikeleri, olumsuz koşulları ve diğer sürücülerin eylemlerini öngörme ve bunlara etkili bir şekilde yanıt verme becerilerini geliştirmek.
							<br /><br />
							Özellikler: Çarpışmalardan kaçınma teknikleri, acil durum koşullarında araç dinamiklerini anlama ve tepki sürelerini iyileştirme stratejileri. Bu kurs tüm deneyim seviyelerindeki sürücüler için faydalıdır.
							<br /><br />
							Süre: 8 saatlik interaktif sınıf ve simülatör oturumları.

						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							İleri Sürüş Teknikleri
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Amaç: Yüksek hızlı ortamların, hassas manevraların ve gelişmiş araç kontrolünün üstesinden gelmek için sürüş becerilerini keskinleştirin.
							<br /><br />
							Özellikler: Performanslı sürüşe, gelişmiş araç dinamiklerine ve hassas kontrole odaklanın. Meraklılar veya profesyonel sürüş kariyeri düşünenler için idealdir.
							<br /><br />
							Süre: En az 12 saatlik ileri sürüş pratiği ile seçilen belirli modüllere göre değişir.

						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Eko-Sürüş Kursu
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Amaç: Sürücülere verimli sürüş teknikleriyle çevresel etkilerini nasıl en aza indireceklerini öğretmek.
							<br /><br />
							Özellikler: Optimum vites kullanımı, hız yönetimi ve çevre dostu araç bakım ipuçları dahil olmak üzere yakıt tüketimini ve emisyonları azaltmaya yönelik stratejiler.
							<br /><br />
							Süre: 5 saatlik sınıf eğitimi ve pratik sürüş alıştırmaları.

						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Kıdemli Tazeleme Kursu
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Amaç: Yaşlı sürücüleri güncel karayolu yasaları konusunda güncellemek, sürüş becerilerini tazelemek ve yaşlanan sürücülerin karşılaştığı yaygın zorlukları ele almak.
							<br /><br />
							Özellikler: Defansif sürüş, güven artırıcı stratejiler ve tepki süreleri ve hareketlilikteki değişikliklere uyum sağlamak için sürüş alışkanlıklarını uyarlamaya odaklanın.
							<br /><br />
							Süre: Bireysel değerlendirmeye göre özelleştirilebilir, tipik olarak 6 ila 8 saatlik birleşik sınıf ve pratik eğitim.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Bize Ulaşın
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Kurslarımız hakkında ayrıntılı programlar ve nasıl kayıt olunacağı da dahil olmak üzere daha fazla bilgi için sizi bizimle iletişime geçmeye davet ediyoruz. Ekibimiz mükemmel kursu seçmenize ve mükemmel sürüş yolculuğunuza başlamanıza yardımcı olmaya hazır.
					</Text>
					<Button
						background="#0438cd"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Kişiler
					</Button>
				</Box>
				<Image src="https://wovado.com/img/7.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});